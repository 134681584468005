import React, { useState } from 'react';
import { Navbar as NavbarBase, NavItem, NavLink, Badge } from 'reactstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';
import NavbarUser from './NavbarUser';
import * as Icon from 'react-feather';
import {
  setCreateCategoryModalData,
  setCreateInfoModalData,
  setCreateItemModalData,
  setIsCreateAdditionModalOpen,
  setIsCreateCategoryModalOpen,
  setIsCreateInfoModalOpen,
  setIsCreateItemModalOpen,
  setIsEditMenuModalOpen,
  setIsListAdditionsModalOpen,
} from '../redux/modal/modal.actions';
import { useParams } from 'react-router-dom';
import { isAdminMode } from '../utility/util/util.service';
import PropTypes from 'prop-types';
import { setIsSidebarOpen } from '../redux/customizer/customizer.actions';
import { FormattedMessage } from 'react-intl';

const Navbar = ({
  navbarColor,
  navbarType,
  horizontal,
  scrolling,
  sidebarVisibility,
  handleAppOverlay,
  changeCurrentLang,
  table,
  me,

  setIsCreateCategoryModalOpen,
  setCreateCategoryModalData,
  setIsCreateItemModalOpen,
  setCreateItemModalData,
  setIsListAdditionsModalOpen,
  setIsEditMenuModalOpen,
  setIsSidebarOpen,
  setIsCreateInfoModalOpen,
  setCreateInfoModalData,
}) => {
  const { slug, categoryId } = useParams();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const colorsArr = ['primary', 'danger', 'success', 'info', 'warning', 'dark'];
  const navbarTypes = ['floating', 'static', 'sticky', 'hidden'];

  const openCreateItemModal = () => {
    setCreateItemModalData({
      isVariation: false,
      targetCategoryId: categoryId || null,
    });
    setIsCreateItemModalOpen(true);
  };
  const openCreateCategoryModal = () => {
    setCreateCategoryModalData({
      parentCategoryId: categoryId || null,
    });
    setIsCreateCategoryModalOpen(true);
  };
  const openCreateAdditionModal = () => {
    setIsListAdditionsModalOpen(true);
  };
  const openCreateInfoModal = () => {
    setCreateInfoModalData({ targetCategoryId: categoryId || null });
    setIsCreateInfoModalOpen(true);
  };

  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <div className="navbar-content-wrepper">
        <NavbarBase
          className={classnames(
            'header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow',
            {
              'navbar-light':
                navbarColor === 'default' || !colorsArr.includes(navbarColor),
              'navbar-dark': colorsArr.includes(navbarColor),
              'bg-primary':
                navbarColor === 'primary' && navbarType !== 'static',
              'bg-danger': navbarColor === 'danger' && navbarType !== 'static',
              'bg-success':
                navbarColor === 'success' && navbarType !== 'static',
              'bg-info': navbarColor === 'info' && navbarType !== 'static',
              'bg-warning':
                navbarColor === 'warning' && navbarType !== 'static',
              'bg-dark': navbarColor === 'dark' && navbarType !== 'static',
              'd-none': navbarType === 'hidden' && !horizontal,
              'floating-nav':
                (navbarType === 'floating' && !horizontal) ||
                (!navbarTypes.includes(navbarType) && !horizontal),
              'navbar-static-top': navbarType === 'static' && !horizontal,
              'fixed-top': navbarType === 'sticky' || horizontal,
              scrolling: horizontal && scrolling,
            },
          )}
        >
          <div className="navbar-wrapper">
            <div className="navbar-container content">
              <div
                className="navbar-collapse d-flex justify-content-between align-items-center"
                id="navbar-mobile"
              >
                <div className="bookmark-wrapper">
                  {/*<NavbarBookmarks
                  sidebarVisibility={sidebarVisibility}
                  handleAppOverlay={handleAppOverlay}
                />*/}
                  <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                    <ul className="navbar-nav d-xl-none">
                      <NavItem className="mobile-menu mr-auto">
                        <NavLink
                          className="nav-menu-main menu-toggle hidden-xs is-active"
                          onClick={() => setIsSidebarOpen(true)}
                        >
                          <Icon.Menu className="ficon" />
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                </div>
                {/*{horizontal && (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">servier.bar</h2>
                </div>
              )}*/}
                <NavbarUser
                  handleAppOverlay={() => setIsSidebarOpen(false)}
                  isSearchOpen={isSearchOpen}
                  setIsSearchOpen={setIsSearchOpen}
                />
              </div>
            </div>

            {isAdminMode(me, slug) && (
              <>
                <hr className="m-0" />
                <div className="d-flex align-items-center justify-content-around p-1">
                  <span onClick={openCreateCategoryModal}>
                    <Icon.FolderPlus
                      data-testid="categoryCreation"
                      size={22}
                      className="secondary"
                    />
                  </span>
                  <span onClick={openCreateItemModal}>
                    <Icon.FilePlus
                      data-testid="newItemCreation"
                      id="AddNewItem"
                      size={22}
                      className="secondary"
                    />
                  </span>
                  <span onClick={openCreateAdditionModal}>
                    <Icon.PlusCircle
                      data-testid="extrasCreation"
                      size={22}
                      className="secondary"
                    />
                  </span>
                  <span onClick={openCreateInfoModal}>
                    <Icon.Info
                      data-testid="infoCreation"
                      size={22}
                      className="secondary"
                    />
                  </span>
                </div>
              </>
            )}
          </div>
          {!isAdminMode(me, slug) && table && !isSearchOpen && (
            <div
              className="position-absolute d-flex justify-content-center w-100"
              style={{ bottom: '-10px' }}
            >
              <Badge color="info">
                <FormattedMessage
                  id="tableActiveBadgeTitle"
                  values={{ tableName: table.name }}
                />
              </Badge>
            </div>
          )}
        </NavbarBase>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  navbarColor: PropTypes.string,
  navbarType: PropTypes.string,
  horizontal: PropTypes.bool,
  scrolling: PropTypes.bool,
  sidebarVisibility: PropTypes.func,
  handleAppOverlay: PropTypes.func,
  changeCurrentLang: PropTypes.func,
  me: PropTypes.object,
  setIsCreateCategoryModalOpen: PropTypes.func,
  setCreateCategoryModalData: PropTypes.func,
  setIsCreateItemModalOpen: PropTypes.func,
  setCreateItemModalData: PropTypes.func,
  setIsListAdditionsModalOpen: PropTypes.func,
  setIsEditMenuModalOpen: PropTypes.func,
};

const mapStateToProps = (state) => ({
  me: state.me.me,
  navbarColor: state.customizer.navbarColor,
  navbarType: state.customizer.navbarType,
  isSidebarOpen: state.customizer.isSidebarOpen,
  table: state.store.table,
});

export default connect(mapStateToProps, {
  setIsCreateCategoryModalOpen,
  setCreateCategoryModalData,
  setIsCreateItemModalOpen,
  setCreateItemModalData,
  setIsCreateAdditionModalOpen,
  setIsEditMenuModalOpen,
  setIsListAdditionsModalOpen,
  setIsSidebarOpen,
  setIsCreateInfoModalOpen,
  setCreateInfoModalData,
})(Navbar);
