import {
  getMe,
  getOrders,
  getPaymentMethods,
  getDeliveryAddresses,
  getBillingAddresses,
} from '../../utility/sb-api/sb-api.service';
import { notification } from '../../utility/notification/notification.service';
import { ORDER_STATUS_PENDING } from '../../utility/constants';

// call this, when isAuthenticated = true or Guest
export const fetchOnAuthenticated = () => (dispatch) => {
  dispatch(fetchMe()).then(() => {
    dispatch(fetchPaymentMethods());
    dispatch(fetchAddresses());
  });
};

// call this, when visitor is anonymous
export const fetchOnUnauthenticated = () => (dispatch) => {
  dispatch(setMe(false)); // don't fetch anything, just set me to false
};

export const setMe = (me) => (dispatch) => {
  dispatch({ type: 'SET_ME', payload: me });
};

export const fetchMe = () => (dispatch) =>
  getMe()
    .then((me) => {
      dispatch(setMe(me));
      return me;
    })
    .catch(() => notification('error', 'fetchMeError'));

export const setFoodAllergensFilter = (foodAllergenIds) => (dispatch) => {
  dispatch({
    type: 'SET_FOOD_ALLERGENS_FILTER',
    payload: foodAllergenIds,
  });
};

export const setFoodPreferencesFilter = (foodPreferenceIds) => (dispatch) => {
  dispatch({
    type: 'SET_FOOD_PREFERENCES_FILTER',
    payload: foodPreferenceIds,
  });
};

export const setFoodIngredientsFilter = (foodIngredientIds) => (dispatch) => {
  dispatch({
    type: 'SET_FOOD_INGREDIENTS_FILTER',
    payload: foodIngredientIds,
  });
};

export const setPickupOrder = (order) => (dispatch) => {
  dispatch({
    type: 'SET_PICKUP_ORDER',
    payload: order,
  });
};

export const fetchOrders = (customQueryParams = {}) => (dispatch) => {
  const queryParams = {
    filter: [['status', '$ne', ORDER_STATUS_PENDING]],
    join: [
      'merchantProfile',
      'merchantProfile.store',
      'merchantProfile.store.logo',
      'billingAddress',
      'deliveryAddress',
      'payment',
      'items',
      'items.itemData',
      'items.itemData.image',
      'items.additions',
      'items.additions.itemAddition',
      'table',
    ],
    sort: [{ field: 'createdAt', order: 'DESC' }],
    ...customQueryParams,
  };

  getOrders(null, queryParams, false).then((orders) => {
    dispatch({
      type: 'SET_ORDERS',
      payload: orders,
    });
    return orders;
  });
};

export const setSpecificOrder = (order) => (dispatch) => {
  dispatch({
    type: 'SET_ME_SPECIFIC_ORDER',
    payload: order,
  });
};

export const fetchPaymentMethods = () => (dispatch) =>
  getPaymentMethods()
    .then((paymentMethods) => {
      dispatch({
        type: 'SET_PAYMENT_METHODS',
        payload: paymentMethods,
      });
      return paymentMethods;
    })
    .catch(() => notification('error', 'defaultResponseError'));

export const fetchAddresses = () => (dispatch) => {
  dispatch(fetchDeliveryAddresses());
  dispatch(fetchBillingAddresses());
};

export const fetchDeliveryAddresses = (
  openedCartModal,
  returnToCartModalFunction,
) => (dispatch) => {
  getDeliveryAddresses()
    .then((addresses) => {
      dispatch({
        type: 'SET_DELIVERY_ADDRESSES',
        payload: addresses,
      });
      openedCartModal && returnToCartModalFunction(true);
      return addresses;
    })
    .catch(() => notification('error', 'defaultResponseError'));
};

export const fetchBillingAddresses = (
  openedOrderInfoModal,
  returnToSelectBillingAddressModal,
  setIsSubmitting,
) => (dispatch) => {
  getBillingAddresses()
    .then((addresses) => {
      dispatch({
        type: 'SET_BILLING_ADDRESSES',
        payload: addresses,
      });
      openedOrderInfoModal && returnToSelectBillingAddressModal(true);
      setIsSubmitting && setIsSubmitting(false);
      return addresses;
    })
    .catch(() => notification('error', 'defaultResponseError'));
};

export const setCreatePaymentMethodClientSecret = (clientSecret) => (
  dispatch,
) => {
  dispatch({
    type: 'SET_CREATE_PAYMENT_METHOD_CLIENT_SECRET',
    payload: clientSecret,
  });
};
/*export const createBillingAddress = (payload) => (dispatch) => {
  createBillingAddress(payload)
    .then((address) => {
      dispatch({
        type: 'SET_BILLING_ADDRESS',
        payload: address,
      });
      return address;
    })
    .catch(() => notification('error', 'fetchMeError'));
};*/
