const APP_FALLBACK_LOCALE = 'en';
const APP_SUPPORTED_LOCALES = ['de', 'en', 'it', 'fr', 'es', 'hu'];
const ENV =
  process.env.REACT_APP_CF_PAGES_BRANCH || process.env.REACT_APP_ENV || 'dev'; // typically dev, demo or prod (name of repo branch)
const isCloudflarePages = !!process.env.REACT_APP_CF_PAGES_BRANCH;

// build the config by using dynamic env tag (it's uppercase!)
const envTag = ['prod', 'demo', 'staging', 'dev'].includes(ENV)
  ? ENV.toUpperCase()
  : 'DEV'; // for all other envs (like branches, etc.)
const config = {
  env: ENV,
  stripePK: process.env.REACT_APP_STRIPE_API_PUBLIC_KEY, // same for demo + dev
  apiBaseUrl: process.env[`REACT_APP_${envTag}_API_BASE_URL`],
  wsBaseUrl: process.env[`REACT_APP_${envTag}_WS_BASE_URL`],
  shortUrl: process.env[`REACT_APP_${envTag}_SHORT_URL`], // e.g. menu.sb short url
  commitSha: process.env.REACT_APP_COMMIT_SHA,
  version: process.env.REACT_APP_VERSION,
  auth0: {
    domain: process.env[`REACT_APP_${envTag}_AUTH0_DOMAIN`],
    clientId: process.env[`REACT_APP_${envTag}_AUTH0_CLIENT_ID`],
    audience: process.env[`REACT_APP_${envTag}_AUTH0_AUDIENCE`],
  },
  cfImages: {
    accountHash: process.env.REACT_APP_CF_IMAGES_ACCOUNT_HASH,
    baseUrl: `${window.location.origin}/cdn-cgi/imagedelivery/${process.env.REACT_APP_CF_IMAGES_ACCOUNT_HASH}`,
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
  },
  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
};

// adjust for local dev or when CF Pages but none of the branches from above
if (config.env === 'dev' && !isCloudflarePages) {
  config.cfImages.baseUrl = `https://imagedelivery.net/${config.cfImages.accountHash}`;
}

export {
  APP_FALLBACK_LOCALE,
  APP_SUPPORTED_LOCALES,
  isCloudflarePages,
  config,
};
